import React, { useState } from 'react';
import BarcodeScanner from 'react-qr-barcode-scanner'; // Import as default
import './App.css'; // Import the CSS file

const BarcodeScannerPage = () => {
  const [barcode, setBarcode] = useState('');
  const [error, setError] = useState('');

  const handleScan = (result) => {
    if (result) {
      console.log('Scanned result:', result); // Debugging log
      setBarcode(result.text);
    }
  };

  const handleError = (err) => {
    console.error('Scan error:', err); // Debugging log
    setError('Error scanning barcode.');
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <h1>Barcode Scanner</h1>
      <div className="video-canvas">
        <BarcodeScanner
          onUpdate={(err, result) => {
            if (result) handleScan(result);
            else handleError(err);
          }}
        />
      </div>
      {barcode && <p>Scanned Barcode: {barcode}</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default BarcodeScannerPage;